

























































































































































































.digitalServices{
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}

.banner{
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner2.png");
}
.main{
  position: relative;
  width: 1440px;
  margin: 0 auto;
  h3{
    position: relative;
    text-align: center;
    padding: 40px 0 40px;
    color: #333;
    font-size: 30px;
    font-weight: bold;
  }
}
.main1{
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: #FFF;
  }
}

.educationalTraining{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  .educationalTInner{
    position: relative;
    width: 460px;
    margin-right: 30px;
    margin-bottom: 40px;
    &:hover{
      &>div{
        background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
        box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
      }
    }
    &:nth-child(3n){
      margin-right: 0;
    }
    img{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100px!important;
      height: 100px!important;
    }
    &>div{
      margin-top: 40px;
      padding: 80px 50px 54px;
      background: linear-gradient(180deg, #F3F5F8 0%, #FAFAFA 100%);
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #FFFFFF;
      text-align: center;
      transition: 0.5s;
      h6{
        margin: 0;
        font-size: 24px;
        color: #333;
        font-weight: bold;
        margin-bottom: 7px;
      }
      p{
        line-height: 1.4;
        font-size: 16px;
        color: #666;
      }
    }
  }
}
.enterpriseTraining {
  /deep/.el-radio-group{
    display: flex;
    background-color: #FFF;
    border-radius: 8px;
  }
  /deep/.el-radio-button{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }
  /deep/.el-radio-button__inner{
    font-size: 20px;
    font-weight: bold;
    border: 0;
    width: 100%;
    border-radius: 8px;
    padding: 25px 0;
    //transition: 0.3s;
    user-select:none;
    background: #FFF;
    //background: linear-gradient(180deg, #FFF 0%, #FFF 100%);
  }
  /deep/.el-radio-button__inner:hover{
    color: #2878ff;
  }
  /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background: #2878FF;
    &:hover{
      color: #fff;
    }
    //background: linear-gradient(180deg, #2878FF 0%, #4678FF 100%);
  }
}

.animation {
  position: relative;
  display: inline-block;
  height: 388px;
  width: 100%;
  overflow: hidden;
  .shade{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.1);
    /deep/.el-button.is-round{
      background: #fff;
      border: 0;
      color: #333;
      transition: 0.5s;
      box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.16);
      font-size: 16px;
      &:hover{
        background: #2878FF;
        color: #fff;
        .el-icon--right{
          color: #fff;
        }
      }
      .el-icon--right{
        color: #2878FF;
      }
    }
  }
  ul{
    width: 3840px;
    animation: run 30s linear infinite;
    li{
      float: left;
      width: 1920px;
      height: 388px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.enterpriseTInfoBox{
  overflow: hidden;
  margin-bottom: 60px;
  .enterpriseTInfoBoxInner{
    width: 5760px;
    height: 388px;
    transition: 0.5s;
    transform: translateX(0);
    &.positionETIBI-1{
      transform: translateX(0);
    }
    &.positionETIBI-2{
      transform: translateX(-25%);
    }
    &.positionETIBI-3{
      transform: translateX(-50%);
    }
    &.positionETIBI-4{
      transform: translateX(-75%);
    }
  }
}

.enterpriseTInfo{
  float: left;
  width: 1440px;
  height: 388px;
  overflow: hidden;
  .enterpriseTInfoInner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 54px 64px 54px 80px;
    background-image: url("../../assets/loginIndexNew/enterpriseTrainingBg.png");
    background-size: cover;
    flex-wrap: wrap;
    img{
      width: 499px!important;
      height: 286px!important;
    }
    .enterpriseText{
      p{
        position: relative;
        line-height: 30px;
        padding-left: 19px;
        font-size: 16px;
        color: #333;
        &:before{
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #FFCB7E;
        }
      }
      /deep/.el-button.is-round{
        background: transparent;
        border: 1px solid #666666;
        color: #666;
        transition: 0.5s;
        font-size: 16px;
        margin-top: 30px;
        &:hover{
          background: #2878FF;
          border: 1px solid #2878FF;
          color: #fff;
          .el-icon--right{
            color: #fff;
          }
        }
        .el-icon--right{
          color: #2878FF;
        }
      }
    }
  }
}

/* 定义动画 */
@keyframes run {
  to {
    transform: translateX(-1920px);
  }
}

@media (max-width: 1500px) {
  .main{
    width: 1200px;
  }
  .banner{
    height: 250px;
  }
  .educationalTraining .educationalTInner{
    width: 380px;
  }
  .enterpriseTraining{
    /deep/.el-radio-button__inner{
      padding: 20px 0;
    }
  }
  .enterpriseTInfoBox{
    .enterpriseTInfoBoxInner{
      width: 4800px;
    }
  }
  .enterpriseTInfo .enterpriseTInfoInner{
    padding: 54px 45px;
  }
  .enterpriseTInfo{
    width: 1200px;
  }
  .enterpriseTInfo .enterpriseTInfoInner img{
    width: 338px!important;
    height: 193px!important;
  }
}
.clearfix:after{
  content:"";//设置内容为空
  height:0;//高度为0
  line-height:0;//行高为0
  display:block;//将文本转为块级元素
  visibility:hidden;//将元素隐藏
  clear:both;//清除浮动
}
.clearfix{
  zoom:1;//为了兼容IE
}
